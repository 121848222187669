import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import * as Config from '../../config'
import { map, catchError } from 'rxjs/operators'
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService  {

  constructor(
    private http: HttpClient,
    public router: Router,
    private util: UtilService

  ) { }



  getdata(url){

    return this.http.get<any>(url)
    .pipe(
      map(result=>result)
    )
  }


  postdata(url,data){

    return this.http.post<any>(url,data)
    .pipe(
      map(result=>result)
    )
  }




}
