
import { environment } from 'src/environments/environment';

export const PUSHSERVER_URL="https://pushenvoy.com/app/api/"
export const PUSHSERVER_TOKEN='16befba9c7a74d9762ed7bd7d58d0bf4';



export const ENDPOINT=`${environment.coreUrl}`
export const FETCH_PRODUCT_URL=ENDPOINT+"fetchproducts/"
export const FETCH_HOT_SELLING=ENDPOINT+"fetchhotsellingproducts/"
export const FETCH_STORE_PRODUCT_URL=ENDPOINT+"fetchproduct/store/"
export const FETCH_CATEGORIES_URL=ENDPOINT+"fetchcategories/"
export const FETCH_ONE_PRODUCT_URL=ENDPOINT+"productpage/"
export const FETCH_RELATED_PRODUCTS=ENDPOINT+"relatedproducts/"
export const LOGIN_URL=ENDPOINT+"auth"

export const  EXCHANGE_ONETIME_KEY=ENDPOINT+"exchange"

export const LOGOUT=ENDPOINT+"logout"
export const CHANGE_PASSWORD=ENDPOINT+"changepassword"
export const  CheckProfileRequirements=ENDPOINT+"checkaccountrequirement"
export const TOKEN_REFRESH_URL=ENDPOINT+"refreshtoken"
export const REGISTER_ENDPOINT=ENDPOINT+"register"
export const FETCH_STORE_PROFILE=ENDPOINT+"fetchstoreprofile"
export const UPDATE_STORE_PROFILE=ENDPOINT+"updatestoreprofile"
export const FETCH_USER_PROFILE=ENDPOINT+"fetchpersonprofile"
export const UPDATE_USER_PROFILE=ENDPOINT+"updateuserprofile"
export const ADD_STORE_LOGO_PROFILE_PICTURE=ENDPOINT+"updatestorelogo"

export const Password_Reset_Request=ENDPOINT+"resetpasswordrequest"
export const Password_Reset_Response=ENDPOINT+"resetpasswordresponseset"


export const RESEND_EMAIL_CONFIRMATIONLINK=ENDPOINT+"resendconfirmemailcode"
export const Change_EMAILADDRESS=ENDPOINT+"changeemailaddress"
export const Confirm_Email=ENDPOINT+"confirmemail"


/// user account related ///
export const FETCH_PAYMENT_GATEWAYS=ENDPOINT+"paymentgateways"
export const FETCH_STATES=ENDPOINT+"fetchstates"
export const FETCH_LGA=ENDPOINT+"fetchlga"
export const CHECKOUT_ADDRESSES=ENDPOINT+"checkoutaddress/"
export const FETCH_PRODUCT_SHIP_COST=ENDPOINT+"productshipcost/"
export const FETCH_PROCESSING_FEE=ENDPOINT+"processingfee/"
export const FETCH_QTY_PRICE=ENDPOINT+"quantityprice/"
export const PLACE_ORDER=ENDPOINT+"userorders/"

export const FETCH_GATEWAY=ENDPOINT+"fetchgateway/" 
export const CARDPAYMENT_CALLBACK=ENDPOINT+"ordercardcallback/"
export const FETCH_MYPURCHASES=ENDPOINT+"mypurchases/"
export const FETCH_CUSTOMER_DIRECT_ORDER=ENDPOINT+"fetchcustomerdirectorders/"

export const FETCH_ORDERSTATUS=ENDPOINT+"orderstatus/"
export const FETCH_ORDERLIST_STATUS=ENDPOINT+"orderliststatus/"
export const CONFIRM_PRODUCT_DELIVERY=ENDPOINT+"markordercomplete/" /// customer list
export const MARK_AS_DELIVERD=ENDPOINT+"markdelivered/"  /// mark deliverd
export const FETCH_CREATE_PRODUCT_PARAMS=ENDPOINT+"createproductparams/"
export const CREATE_PRODUCT=ENDPOINT+"createproduct/"
export const ADD_PRODUCT_PHOTO=ENDPOINT+"addproductphoto/"
export const DELETE_PRODUCT_PHOTO=ENDPOINT+"deleteproductphoto/"
export const GET_BUSINESS_PRODUCTS=ENDPOINT+"getuserproducts/"
export const GET_BUSINESS_PRODUCT_BASIC=ENDPOINT+"getuserproductsbasic/"
export const GET_BUSINESS_ONE_PRODUCTS=ENDPOINT+"getstoreoneproduct/"
export const UPDATE_PRODUCT=ENDPOINT+"updateproduct/"
export const SET_DEFAULT_IMAGE=ENDPOINT+"setdefualtimage/"


export const CREATE_SALES_INVOICE=ENDPOINT+"createsalesinvoice/"
export const UPDATE_SALES_INVOICE=ENDPOINT+"updateinvoice/"
export const FETCH_SALES_INVOICE=ENDPOINT+"getsalesinvoices/"
export const CANCEL_ORDER=ENDPOINT+"vendorcancelorder/"


export const CHECK_SHIPPING_SETUP=ENDPOINT+"checkshippingsetup"
export const FETCH_SHIPPING_COST=ENDPOINT+"getshippingsetups"
export const DELETE_SHIPPING_COST=ENDPOINT+"deleteshipcost"
export const MODIFY_SHIPPING_COST=ENDPOINT+"modifyshipclass"
export const FETCH_CREATESHIP_COST_DATA=ENDPOINT+"createshippingdata"
export const ADD_SHIPPING_COST=ENDPOINT+"addshippingcost"
export const ADD_WAYBILL_NUMBER=ENDPOINT+"addwaybillnumber"

export const FETCH_PLAN_SUBSCRIPTION=ENDPOINT+"getaccountplans"
export const SUBSCRIBE_PLAN=ENDPOINT+"registerplansubscription"
export const PLAN_PAYMENT_CALLBACK=ENDPOINT+"planpaymentcallback"
export const SET_AUTO_RENEW=ENDPOINT+"setautorenew"

export const GET_BANNER=ENDPOINT+'getbanner/'



/// Chat Socket ///
export  const CHAT_SERVER_CORE=`${environment.chatSocket}`
export  const CHAT_SERVER=`${environment.chatApi}`
export const GET_CHATCOVNERSATION_ITEMS=CHAT_SERVER+"getconversationchat/"
export const GET_REMOTESTORE_CHAT_INFO=CHAT_SERVER+"getstoreinfomation/"
export const GET_CHATLIST=CHAT_SERVER+'getconversations/' 
export const START_CONVERSATION=CHAT_SERVER+'startconversation/'
export const DELETE_CHAT_MESSAGE=CHAT_SERVER+"deletechatmessage/"
export const GET_UNREAD_CHATS=CHAT_SERVER+"unreadchats/"

export const FOLLOW_SELLER=CHAT_SERVER+'followseller/'
export const GET_FOLLOWINGS=CHAT_SERVER+'followings/'
export const  SAVE_PUSH_NOTIFICATION_TOKEN=CHAT_SERVER+"savepushtoken/"