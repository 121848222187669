import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import * as Config from '../config'
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { take, tap, delay, switchMap, map,catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../pages/user/auth/login/modal/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userIsAuthenticated = false
  private refreshTokenTimeout;
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private platform: Platform,
    private router:Router
  ) {
    this.userSubject = new BehaviorSubject<User>(null);
    this.user = this.userSubject.asObservable();
   }


  getJwtToken() {
    return localStorage.getItem("access_token");
  }
  private getRefreshToken() {
    return localStorage.getItem("refresh_token");
  }

  check_login_token(){

    return localStorage.getItem("access_token")
  
  }

  public get userValue(): User {
    return this.userSubject.value;
}

changeEmail(data){
  return this.http.post<any>(Config.Change_EMAILADDRESS,data)
  .pipe(
    map(result=>result)
  )
}


  startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.userValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    console.log("jwt expires in",expires)
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);

   
}

refreshToken() {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer '+this.getRefreshToken()
    })
  }
  console.log("Will call refresh token called")
  return this.http.post<any>(Config.TOKEN_REFRESH_URL,'',httpOptions).pipe(
    tap((res) => {
      this.userSubject.next({
          id: res.id,
          jwtToken:res.access_token
      });
      this.startRefreshTokenTimer()
      
    this.storeJwtToken(res.access_token);
  }))
}


stopRefreshTokenTimer() {
  
    clearTimeout(this.refreshTokenTimeout);
}

  Register(payload){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }
    return this.http.post<any>(Config.REGISTER_ENDPOINT,payload,httpOptions)
    .pipe(
      map(res=>res)
    )

  }

  passwordResetRequest(data){
    return this.http.post<any>(Config.Password_Reset_Request,data)
    .pipe(
      map(res=>res)
    )
  }

  passwordResetResponse(data){
    return this.http.post<any>(Config.Password_Reset_Response,data)
    .pipe(
      map(res=>res)
    )
  }
 

  loginWithEmailPassword(email: string, password: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': ''
      })
    }

    let payload = { "email": email, "password": password }

    return this.http.post<any>(Config.LOGIN_URL, payload, httpOptions)
    .pipe(
      map(res => {
        
        this.userSubject.next({
          id: res.userdata.userid,
          jwtToken:res.access_token
      });
      this.startRefreshTokenTimer();
        return res
      })
    )
  }

 
  logout_cleaner(){
  
    this.stopRefreshTokenTimer()
    
    this.storage.remove("user_data")
    localStorage.removeItem('user_data')
    localStorage.removeItem('userData')
    localStorage.removeItem('userdata')
    localStorage.removeItem('access_token')
    localStorage.removeItem('refresh_token')
    //this.userSubject.next(null);
    return 
  }


  logout() {
    
    let data={}
    return this.http.post<any>(Config.LOGOUT,data)
    .pipe(
      map(res=>{
        console.log("ne hereee")
          this.logout_cleaner()
        
        return res
      })
    )
  
  }

  

resendEmailConfirmationLink(email){
    let data={}
    data['email']=email
    return this.http.post<any>(Config.RESEND_EMAIL_CONFIRMATIONLINK,data)
    .pipe(
      map(result=>result)
    )
   
  }

VerifyEmail(data){
  return this.http.post<any>(Config.Confirm_Email,data)
  .pipe(
    map(result=>result)
  )
 
}

checkProfileRequirement(){
  /*console.log("here here ")
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("access_token")
    })
  }
  */
  
  return this.http.get<any>(Config.CheckProfileRequirements)
  .pipe(
    map(res=>res)
  )

}


ChangePassword(data){	
  return this.http.post<any>(Config.CHANGE_PASSWORD,data)	
  .pipe(	
    map(result=>result)	
  )	
}


uploadprofilephoto(formData) {

  return   this.http.post<any>(Config.ADD_STORE_LOGO_PROFILE_PICTURE, formData, {  
      reportProgress: true,  
      observe: 'events'  
    });  
}





 storeJwtToken(jwt: string) {
    localStorage.setItem("access_token", jwt);
  }


  handleError(error: HttpErrorResponse) {
    console.log(error)

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError(error.error.message)
    }
    else if(error.status === 401){
      
      
      return throwError(error.error.message)
    }
     else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.message}`);
        return throwError(error.error.message)
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
    return throwError('Something bad happened; please try again later.');
  };
  


  save_PushToken(data){	
    return this.http.post<any>(Config.SAVE_PUSH_NOTIFICATION_TOKEN,data)	
    .pipe(	
      map(res=>res)	
    )	
  }

  
  postdata(url,data){

    return this.http.post<any>(url,data)
    .pipe(
      map(result=>result)
    )
  }

  
}
