
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController, ToastController, AlertController, } from '@ionic/angular';
import { AuthService } from '../auth.service';
//import { SnapcashAuth } from './snapcashApi/snapcashAuth';
//import { App } from 'ionic-angular';
import { appMsgs } from './messages';





@Injectable()
export class AppServices {
    constructor(
        private loader: LoadingController,
        private appmsg: appMsgs,
        private authService: AuthService,
        private router: Router
 
    ) { }

    
    custome_http_error_handler(err: any,txntype='') {

        
        
        if (err.status == 401) {
                //// User session Expired //
                      this.authService.logout().subscribe((res: any) => {
                        ////  Send User to Dashboard Here ///
                        this.router.navigate(['/auth/login']);
                       //this.router.navigateByUrl('/auth');
    
    
                    })
    
            }
            else if (err.status == 0) {
                
              this.appmsg.presentToast("Error connecting to service",5000)
            }
    
       
            else if(err.status==400){
                if(txntype=='form'){
                    this.ServerFormError(err)
        
                }
                else{
                    this.appmsg.presentToast(err.error.message)
                }
            }
           
            else {
              this.appmsg.presentPopAlert("Error",err.error.message)
               // this.appmsg.presentToast("Could not connect to service.Try again")
            }
    
        }
    
    
        ServerFormError(err){
          if(err.error.form_errors){
            /// Actaull form message  from masmellow//
    
          
    
           
                  let error_messages=[]
                  for (let msg of err.error.form_errors){
                
                    error_messages.push(msg)
                    
                  }
                
                    let msgs="<ul>"
                
                    for(let err of error_messages){
                    msgs=msgs+`<li>${err}</li>`
                    }
                    msgs=msgs+'</ul>'
                    console.log(msgs)
    
                    this.appmsg. presentPopAlert('Error in your data',msgs)
           
            }
            else{
              this.appmsg.presentPopAlert("Error",err.error.message)
            }
    
          }



}
