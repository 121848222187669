import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { GuardGuard } from './pages/user/auth/guard.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo:"user",
    pathMatch:"full"
    
  },


  {
    path: 'auth',
    loadChildren: () => import('./pages/user/auth/login/auth.module').then( m => m.AuthPageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserModule),
    
  },
  
  {
    path: 'exchange',
    loadChildren: () => import('./pages/exchange/exchange.module').then( m => m.ExchangePageModule),
    
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
