import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ChatService } from 'src/app/services/user/chat.service';
import { AppServices } from 'src/app/services/util/appServices';
import { appMsgs } from 'src/app/services/util/messages';
import * as Config from "../../config"

@Component({
  selector: 'app-chatlist',
  templateUrl: './chatlist.component.html',
  styleUrls: ['./chatlist.component.scss'],
})
export class ChatlistComponent implements OnInit {

  filterTerm: string;
  page=1
  public segmentTab: any;
  public chatData: Array<any>;
  public Followers: Array<any>;
  currentUser=""
  private chatListinterval:any

  private unsubscribe: Subscription[]=[]
  constructor(
    private appService: AppServices,
    private  chatService : ChatService,
    private router: Router,
    private appmsg: appMsgs,
    private authService: AuthService,
  ) {
    this.segmentTab = 'chat';
   }

  ngOnInit() {
    if (this.authService.getJwtToken()) {
    this.fetchChatList()
    const userinfo=JSON.parse(localStorage.getItem('userdata'))
    this.currentUser=userinfo.email
    this.chatListinterval=setInterval(() => this.fetchChatList(), 60000);
    }
    
    
  }

  ionViewWillEnter(){
    console.log("chat fetched")
    
    
  }

  segmentChanged(event: any) {
    this.segmentTab = event.detail.value;
    if(event.detail.value=="following"){
       this.fetchFollowings()
    }
  }


  fetchChatList(){
    console.log("chatlist about to run")
    let url=Config.GET_CHATLIST+"all/"+this.page
    const process=this.chatService.getdata(url).subscribe(res=>{
      this.chatData=res

    },err=>{
      this.appService.custome_http_error_handler(err)
    })
  this.unsubscribe.push(process)
  }

  
  fetchFollowings(){
    //store you follow//
    let url=Config.GET_FOLLOWINGS
    this.appmsg.presentLoadingPop()
    const process=this.chatService.getdata(url).subscribe(res=>{
      this.appmsg.dismissLoadingPop()
     console.log(res)
     this.Followers=res.data

    },err=>{
      this.appService.custome_http_error_handler(err)
    })
  this.unsubscribe.push(process)
  }


 
  
  ionViewWillLeave(){
  
    clearInterval(this.chatListinterval)
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }


}
