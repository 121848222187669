import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NavController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';


 
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService,private NavCtl : NavController) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401, 403].includes(err.status) && this.authenticationService.userValue) {
                // auto logout if 401 or 403 response returned from api
                console.log("intercet")
                this.authenticationService.logout_cleaner()
                this.NavCtl.navigateRoot("/auth")
                //this.router.navigate(['/auth'], { queryParams: { returnUrl: `/user/${segments}` }});
            }
            else if([401].includes(err.status)) {
                //console.log("Found a 401 Error elseish")
                this.authenticationService.logout_cleaner()
                this.NavCtl.navigateRoot("/auth")
             
              
            }

          //  const error = (err && err.error && err.error.message) || err.statusText;
           /// console.error(err);
            return throwError(err);
        }))
    }
}

export const ErrorauthInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ];