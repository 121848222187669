import { Injectable} from '@angular/core';
import { LoadingController,ToastController, AlertController, Platform, } from '@ionic/angular';
//import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';

@Injectable()
export class appMsgs{
  isLoading = false;

constructor(
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    //private spinnerDialog: SpinnerDialog,
    ){

}
 async presentToast(message,duration=5000,position="bottom"){

    const msg = await this.toastCtrl.create({
      message: message,
      duration: duration,
      position: "bottom"
    })
    await msg.present()
  }

 async presentPopAlert(title,msg,subtitle=''){
    let alert = await this.alertCtrl.create({
     header: title,
      subHeader: subtitle,
      message:msg,
      cssClass : "alert-warning",
      buttons: ['Ok']
    });
   await  alert.present();
  }




 async presentPopAlertSuccess(title,subtitle){
    let alert = await  this.alertCtrl.create({
      header: title,
      subHeader: "<div text-center><img src='assets/images/goodCheck.png'>"+subtitle+"</div>",
      cssClass : "alert-warning",
      buttons: [{
        text: 'Ok',
        handler: () => {
          console.log('Buy clicked');
        }
      }]
    });
   await  alert.present();
  }


  async presentLoadingPop(content='processing...',timeout=20000){
    this.isLoading = true;
    //if(this.platform.is('cordova')){
    //  this.spinnerDialog.show(content)
   // }
    //else{
       
   return await this.loadingCtrl.create({
    message: content,
    duration:10000,//// just in case the server did not come back on time
  }).then(a => {
    a.present().then(() => {
      console.log('loading presented');
      if (!this.isLoading) {
        a.dismiss().then(() => console.log('abort laoding'));
      }
    });
  });

   // }
  
  

  }

  async dismissLoadingPop() {
    this.isLoading = false;
    //if(this.platform.is('cordova')){
     // this.spinnerDialog.hide()
    //}
    //else{
        return await this.loadingCtrl.dismiss().then(() => console.log('loading dismissed'));
    //}
    
  
  }
  

 async confirmationAlert(title: string,message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    let promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      //enableBackdropDismiss: false,
      cssClass : "alert-subscribe",
      buttons: [ {
        text: 'No',
        handler: () => resolveFunction(false)
      }, {
        text: 'Yes',
        handler: () => resolveFunction(true)
      } ]
    });
    await alert.present();
    return promise;
  }

  
 async presentPopAlertLogout(title,subtitle): Promise<boolean>{
    let resolveFunction: (confirm: boolean) => void;
    let promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    
    let alert =await  this.alertCtrl.create({
      header: title,
      subHeader: subtitle,
      cssClass : "alert-warning",
      buttons: [{
        text: 'Ok',
        handler: () => resolveFunction(true)
      } ]
    });
   await  alert.present();
    return promise;
  }


  
}