// application-pipes.module.ts
// other imports
import { LimitWordPipe } from '../pipes/filter.pipe';
import { NgModule } from '@angular/core';


@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    LimitWordPipe,
  //  FormatMoney
  ],
  exports: [
    LimitWordPipe,
 //   FormatMoney
  ],
  

})
export class ApplicationPipesModule {}