import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatlistComponent } from './chatlist.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ApplicationPipesModule } from 'src/app/specials/shared_pipe_module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [ChatlistComponent],
  imports: [
    ApplicationPipesModule,
    CommonModule,
    Ng2SearchPipeModule,
    FormsModule,
    IonicModule,
    RouterModule
  ],
  exports:[ChatlistComponent]
})
export class ChatlistModule { }
