import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';


//import { AuthService } from '../services/general/auth.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';


@Injectable()

export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        try {
           // console.log("About to call Jwt")
        // add auth header with jwt if user is logged in and request is to the api url
        const user = this.authenticationService.userValue;
        const isLoggedIn = user && user.jwtToken;

        const coreServer = request.url.startsWith(environment.coreUrl);
        const chatSocket = request.url.startsWith(environment.chatSocket);
        const chatApi =    request.url.startsWith(environment.chatApi);

        let userToken = this.authenticationService.getJwtToken()
    

        if ((coreServer || chatSocket || chatApi) && userToken) {

            if (!request.url.endsWith("refreshtoken")) {
              //  console.log("Will add token to ", request.url)

                // Don't Add  new Authoriztion header if already exits, this will  allow refresh token to work
                request = request.clone({
                    // setHeaders: { Authorization: `Bearer ${user.jwtToken}` }
                    setHeaders: { Authorization: `Bearer ${userToken}` }
                });

            }
            return next.handle(request)
        }
        else{
            return next.handle(request)
        }
        } catch (error) {
          //  console.log("auth error",error)
          console.log("some error")
          return next.handle(request)
        }
        
    }
}

export const JwtauthInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
];