import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwiperModule } from 'swiper/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Config from './config'
import { JwtauthInterceptorProviders } from './_helpers/jwt.interceptor';
import { ErrorauthInterceptorProviders } from './_helpers/error.interceptor';
import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppServices } from './services/util/appServices';
import { appMsgs } from './services/util/messages';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Storage } from '@ionic/storage-angular';
import { ApplicationPipesModule } from './specials/shared_pipe_module';
import { ChatlistModule } from './components/chatlist/chatlist.module';
//const config: SocketIoConfig = { url: Config.CHAT_SERVER_CORE, options: {} };



@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    
    SwiperModule,
    BrowserModule, 
    IonicModule.forRoot(), 
    AppRoutingModule, 
    BrowserAnimationsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    ChatlistModule
  //SocketIoModule.forRoot(config),
  ],
  providers: [
    JwtauthInterceptorProviders,
    ErrorauthInterceptorProviders,
    AppServices,
    appMsgs,
    
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
