import { Pipe, PipeTransform } from '@angular/core';



@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    return  value;
  }

}

@Pipe({
  name: 'truncate'
})

///{{ str | truncate:[20] }} // or 
//{{ str | truncate:[20, '...'] }} // or
export class TruncatePipe implements PipeTransform {

  transform(value: string, args: string[]): string {
    console.log("this is the value", value)
    console.log("this is the limit", args[0])
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

@Pipe({
  name: 'formatmoney'
})

///{{ str | truncate:[20] }} // or 
//{{ str | truncate:[20, '...'] }} // or
export class FormatMoney implements PipeTransform {

  transform(value: string, args: string[]): string {
    var formatter = new Intl.NumberFormat('en-NG', {
      style: 'currency',
      currency: 'NGN',
    });
 parseInt
    let money=formatter.format(parseInt(value))
    return money
  }
}



@Pipe({
  name: 'limitword'
})

//limitword:[10] 
export class LimitWordPipe implements PipeTransform {


  transform(value: string, args: number[]): string {
    var textToLimit = value
    //var wordLimit=parseInt(args[0])
    var wordLimit=args[0]

    var finalText = "";

    var text2 = textToLimit.replace(/\s+/g, ' ');

    var text3 = text2.split(' ');

    var numberOfWords = text3.length;

    var i = 0;

    if (numberOfWords > wordLimit) {
      for (i = 0; i < wordLimit; i++)
        finalText = finalText + " " + text3[i] + " ";

      return finalText + "...";
    }
    else {
      return textToLimit;
    }
  }
}

