import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { appMsgs } from './services/util/messages';
import { ChatService } from './services/user/chat.service';
import { AppServices } from './services/util/appServices';
import * as Config from "./config"
import { Subscription } from 'rxjs';
import { Storage } from '@ionic/storage-angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss','./styles/theme.scss','./styles/general.scss'],
})
export class AppComponent implements OnInit {

  selectedIndex=0;

  public showChildren = '';
  public sidemenu = 1;

  public chatData: Array<any>;
  public Followers: Array<any>;
 

  constructor(
    private platform: Platform,
    private storage: Storage,
    
  ) {
    //routingState.loadRouting();
    this.storage.create();
    this.initializeApp();
  }
  
  ngOnInit() {
  
  }


  initializeApp() {
    this.platform.ready().then((source) => {
      this.storage.create();
    });
  }


   
}
